import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete, Pause, PlayArrow, Edit } from "@material-ui/icons";

import moment from "moment";
import { IconButton } from "@material-ui/core";
import ApiRequests from "../utils/ApiRequests";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  _id,
  name,
  appName,
  language,
  phone,
  isActive,
  createdAt,
  updatedAt
) {
  return {
    _id,
    name,
    appName,
    language,
    phone,
    isActive,
    createdAt,
    updatedAt,
  };
}

export default function ReadersTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.name,
        element.appName,
        element.language,
        element.phone,
        element.isActive,
        element.createdAt,
        element.updatedAt
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Language</TableCell>
            {/* <TableCell>Phone</TableCell> */}
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
            {/* <TableCell>Last Login At</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              onClick={() => {
                // props.onClick(row._id);
                // props.history.push("/admin/readers-management/" + row._id);
              }}
              // style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.appName || "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name || "-"}
              </TableCell>
              <TableCell>{row.language}</TableCell>
              <TableCell>{row.isActive ? "Active" : "Paused"}</TableCell>
              <TableCell>
                {moment(row.createdAt).format("MMMM Do, h:mm a")}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={async () => {
                    try {
                      await ApiRequests.patch("/api/data/readers/" + row._id, {
                        isActive: !row.isActive,
                      });
                      alert("Reader updated!");
                      props.reload();
                    } catch (error) {
                      alert("Something went wrong!");
                    }
                  }}
                >
                  {row.isActive ? <Pause></Pause> : <PlayArrow></PlayArrow>}
                </IconButton>
                <IconButton
                  onClick={async () => {
                    try {
                      await ApiRequests.delete("/api/data/readers/" + row._id);
                      alert("Reader deleted!");
                      props.reload();
                    } catch (error) {
                      alert("Something went wrong!");
                    }
                  }}
                >
                  <Delete></Delete>
                </IconButton>
                <IconButton
                  component={Link}
                  to={"/admin/readers-management/" + row._id}
                >
                  <Edit></Edit>
                </IconButton>
              </TableCell>
              {/* <TableCell>
                
              </TableCell> */}
              {/* <TableCell>
                {moment(row.updatedAt).format("MMMM Do, h:mm a")}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
