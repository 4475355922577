import React, { Component } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ApiRequests from "../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import Header from "../../components/Header";

export default class AddReader extends Component {
  state = {
    appName: null,
    name: null,
    language: null,
    dirName: null,
    photoUrl: null,
    mode: null,
    freeContent: null,
    msgHeading: null,
    msgBody: null,
  };

  addReader = async () => {
    try {
      await ApiRequests.post("/api/data/readers", {
        ...this.state,
        freeContent:
          this.state.freeContent == null
            ? []
            : this.state.freeContent.split(","),
      });
      alert("Reader added successfully!");
      window.location.reload();
    } catch (error) {
      alert("Something went wrong");
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <Header title={"Add Reader"} />

        <Grid container spacing={3}>
          {[
            { field: "appName", label: "Display Name" },
            { field: "name", label: "Description (below name)" },
            { field: "language", label: "Language", placeholder: "Arabic" },
            {
              field: "dirName",
              label: "Audios Folder Name",
              hint: "Name of the folder in: ~/public_ftp/qat/readers/",
              placeholder: "omar_jabbie",
            },
            {
              field: "photoUrl",
              label: "Photo File Name",
              hint: "Name of image in the folder: ~/app/data/reader_photos/",
              placeholder: "omar_jabbie",
            },
            { field: "mode", label: "Mode", placeholder: "1" },
            { field: "msgHeading", label: "Popup Message (Heading)" },
            { field: "msgBody", label: "Popup Message (Body)" },
          ].map((element, index) => (
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id={element.field}
                label={element.label}
                name={element.field}
                placeholder={element.placeholder || element.label}
                autoComplete={element.field}
                value={this.state[element.field]}
                onChange={this.handleChange}
                multiline={
                  element.field == "msgBody" || element.field == "name"
                    ? true
                    : false
                }
              />
              {element.hint && <small>{element.hint}</small>}
            </Grid>
          ))}
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="freeContent"
              label="Juzs Available For Free"
              placeholder="1,2,3"
              name="freeContent"
              autoComplete="freeContent"
              value={this.state.freeContent}
              onChange={this.handleChange}
            />
            <small>Comma Separated Values. e.g. 1,2,3,20,25</small>
            <br />
            <FormControlLabel
              value="all"
              control={<Checkbox color="primary" />}
              label="All Juzs are available for free"
              labelPlacement="end"
              onChange={(e) => {
                this.setState({
                  freeContent: !e.target.checked
                    ? ""
                    : "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30",
                });
              }}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={this.addReader}>
          Add Reader
        </Button>
      </div>
    );
  }
}
