import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(
  _id,
  name,
  address,
  phone,
  totalAmount,
  createdAt,
  status,
  paymentStatus
) {
  return {
    _id,
    name,
    address,
    phone,
    totalAmount,
    createdAt,
    status,
    paymentStatus,
  };
}

export default function PickupsTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.user.name,
        element.user.address,
        element.user.phone,
        element.totalAmount,
        element.createdAt,
        element.status,
        element.paymentStatus
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Requested At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Payment Status</TableCell>
            {/* <TableCell>Last Active At</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              onClick={() => {
                props.onClick(row._id);
              }}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name || "-"}
              </TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>{row.phone || "-"}</TableCell>
              <TableCell>{"$" + row.totalAmount || "-"}</TableCell>
              <TableCell>
                {moment(row.createdAt).format("MMM Do, h:mm a")}
              </TableCell>
              <TableCell>{row.status || "-"}</TableCell>
              <TableCell>{row.paymentStatus || "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
