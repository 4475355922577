import React, { Component } from "react";
import { Grid, TextField, Button, Divider } from "@material-ui/core";
import ApiRequests from "../../utils/ApiRequests";
import { CircularProgress, Typography } from "@material-ui/core";
import Header from "../../components/Header";

export default class AppSettings extends Component {
  state = {
    loading: true,
    appVersion: null,
    msgWelcome1: null,
    msgPremium1: null,
    msgOther1: null,
    msgWelcome2: null,
    msgPremium2: null,
    msgOther2: null,
  };

  componentDidMount() {
    this.fetchAppData();
  }

  fetchAppData = async () => {
    try {
      let data = await ApiRequests.get("/api/data/app-settings");
      this.setState({
        loading: false,
        ...data.payload,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      alert("Something went wrong");
    }
  };

  updateSettings = async () => {
    if (this.state.appVersion !== this.state.appVersionText)
      try {
        await ApiRequests.patch("/api/data/app-settings", {
          ...this.state,
        });
        alert("App Settings updated successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
  };

  handleChange = (e) => {
    console.log(e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    if (this.state.loading)
      return (
        <center>
          <CircularProgress color="inherit" />
        </center>
      );
    else if (!this.state.appVersion)
      return (
        <center>
          <Typography>Something went wrong</Typography>
        </center>
      );
    return (
      <div>
        <Header title={"App Settings"} />

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="appVersion"
              label="Latest App Version"
              name="appVersion"
              autoComplete="appVersion"
              value={this.state.appVersion}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {[
            { field: "msgWelcome", label: "Welcome Message" },
            { field: "msgPremium", label: "Premium Content Message" },
            { field: "msgOther", label: "Premium Features" },
            // { field: "name", label: "Description (below name)" },
            // { field: "language", label: "Language", placeholder: "Arabic" },
            // {
            //   field: "dirName",
            //   label: "Audios Folder Name",
            //   hint: "Name of the folder in: ~/public_ftp/qat/readers/",
            //   placeholder: "omar_jabbie",
            // },
            // {
            //   field: "photoUrl",
            //   label: "Photo File Name",
            //   hint: "Name of image in the folder: ~/app/data/reader_photos/",
            //   placeholder: "omar_jabbie",
            // },
            // { field: "mode", label: "Mode", placeholder: "1" },
          ].map((element, index) => (
            <Grid item xs={12}>
              <Grid item xs={4}>
                <Typography variant="h6">{element.label}</Typography>
                <Divider></Divider>
                {element.field != "msgOther" && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={`${element.field}1`}
                    label={`${element.label} (Heading)`}
                    name={`${element.field}1`}
                    placeholder="Heading"
                    value={this.state[`${element.field}1`]}
                    onChange={this.handleChange}
                  />
                )}
                {element.hint && <small>{element.hint}</small>}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={`${element.field}2`}
                  label={`${element.label} (Body)`}
                  name={`${element.field}2`}
                  placeholder="Body"
                  value={this.state[`${element.field}2`]}
                  onChange={this.handleChange}
                  multiline={true}
                />
                {element.hint && <small>{element.hint}</small>}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={this.updateSettings}
        >
          Save
        </Button>
      </div>
    );
  }
}
