import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(_id, name, city, address, phone, openingHours) {
  return { _id, name, city, address, phone, openingHours };
}

export default function SitesTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.name,
        element.city,
        element.address,
        element.phone,
        element.openingHours
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Opening Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              onClick={() => {
                props.onClick(row._id);
              }}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name || "-"}
              </TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.openingHours}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
