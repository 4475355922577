import React, { Component } from "react";
import ReadersTable from "../../components/ReadersTable";
import ApiRequests from "../../utils/ApiRequests";
import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../components/Header";
import UserDetailsModal from "../../components/UserDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Readers extends Component {
  state = {
    loading: true,
    readers: [],
    showModal: false,
    selectedUserId: null,
  };

  componentDidMount() {
    this.fetchReaders();
  }

  fetchReaders = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("/api/data/admin/readers");
      console.log(data);
      this.setState({
        readers: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  selectUser = (id) => {
    console.log("incoming id", id);
    this.setState({
      selectedUserId: id,
      showModal: true,
    });
    console.log("set id", this.state.id);
  };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={"Readers Management"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Readers Management"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchReaders}
            ></RefreshOutlined>
          }
        />
        <Link
          to="/admin/readers-management/add"
          style={{ textDecoration: "none", color: "black" }}
        >
          Add New Reader
        </Link>
        <br />
        <br />
        {/* <Button onClick={()=>{}}>
          Add Reader
        </Button> */}
        {/* <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <UserDetailsModal userId={this.state.selectedUserId} />
          </div>
        </Modal> */}
        {/* <Typography style={{ fontStyle: "italic" }}>
          Click on User's row to view Recent Activity
        </Typography> */}
        <ReadersTable
          list={this.state.readers}
          onClick={(id) => {
            this.selectUser(id);
          }}
          reload={() => this.fetchReaders()}
        />
      </div>
    );
  }
}
