import React from "react";
import { Typography, Divider } from "@material-ui/core";

export default function Header(props) {
  return (
    <div>
      <Typography variant="h5">
        {props.leadingIcon}
        {props.title}
        {props.icon}
      </Typography>
      <br />
      <Divider></Divider>
      <br />
    </div>
  );
}
