import React, { Component } from "react";
import ApiRequests from "../utils/ApiRequests";
import {
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { RefreshOutlined, ArrowBack } from "@material-ui/icons";
import Header from "./Header";
import SearchesTable from "./SearchesTable";
import moment from "moment";

export default class QuoteRequestDetailsModal extends Component {
  state = {
    loading: true,
    details: {},
    quoted: [],
  };

  componentDidMount() {
    this.fetchDetails();
  }

  fetchDetails = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get(`/api/quotes/${this.props.id}`);
      console.log(data);
      this.setState({
        details: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  markAsCompleted = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.patch(`/api/quotes/${this.props.id}`, {
        status: "completed",
      });
      this.setState({
        details: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  updatePricing = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.patch(`/api/quotes/${this.props.id}`, {
        otherItems: this.state.details.otherItems,
      });
      this.setState({
        details: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    let pickupDetails = this.state.details;

    if (this.state.loading)
      return (
        <div>
          <Paper style={{ padding: "32px" }}>
            <Header title={`Quote Request Details`} />
            <center>
              <CircularProgress color="inherit" />
            </center>
            <br />
            <br />
          </Paper>
        </div>
      );
    return (
      <div>
        <Paper style={{ padding: "32px" }} scroll={true}>
          <Header
            leadingIcon={
              <ArrowBack
                style={{ marginRight: "32px", verticalAlign: "middle" }}
                onClick={this.props.handleClose}
              ></ArrowBack>
            }
            title={`Quote Request Details`}
            icon={
              <RefreshOutlined
                style={{ marginLeft: "32px", verticalAlign: "middle" }}
                onClick={this.fetchDetails}
              ></RefreshOutlined>
            }
          />

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h6">Requested By</Typography>
              <Typography variant="subtitle1">
                {`${pickupDetails.user.name}`}
              </Typography>
              <a href={`mailto:${pickupDetails.user.email}`}>
                <Typography variant="subtitle1">
                  {`${pickupDetails.user.email}`}
                </Typography>
              </a>
              <Typography variant="subtitle1">
                {`Phone: ${pickupDetails.user.phone || "-"}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {/* <Typography variant="h6">Preferred Date/Time</Typography>
              <Typography variant="subtitle1">
                {`${moment(pickupDetails.startDateTime).format(
                  "Do MMM, YYYY"
                )} (${moment(pickupDetails.startDateTime).format(
                  "h:mm a"
                )} - ${moment(pickupDetails.endDateTime).format("h:mm a")})`}
              </Typography> */}
              <Typography variant="h6">Status</Typography>
              <Typography variant="subtitle1">
                {`${pickupDetails.status}`}
                {pickupDetails.status === "pending" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.markAsCompleted}
                    style={{ margin: 8 }}
                  >
                    Mark as Completed
                  </Button>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {/* <Typography variant="h6">Total Amount</Typography>
              <Typography variant="subtitle1">
                {`$${pickupDetails.totalAmount}`}
              </Typography> 
              <Typography variant="h6">Payment Status</Typography>
              <Typography variant="subtitle1">
                {`${pickupDetails.paymentStatus}`}
              </Typography> */}
            </Grid>
          </Grid>
          {/* <Typography variant="subtitle1">
            {`${pickupDetails.user._id.email || ""}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${pickupDetails.user._id.phone || ""}`}
          </Typography> */}
          <br />
          <Divider></Divider>
          <br />
          {this.state.loading ? (
            <div>
              <center>
                <CircularProgress color="inherit" />
              </center>
            </div>
          ) : (
            <div>
              <Grid container spacing={3}>
                {pickupDetails.otherItems &&
                  pickupDetails.otherItems.length > 0 && (
                    <Grid item xs={8}>
                      <Typography variant="h6">Item</Typography>
                      <Grid container spacing={3}>
                        {pickupDetails.otherItems.map((element, index) => (
                          <Grid item xs={3}>
                            <center>
                              <a
                                href={`/api/misc/images/${element.fileName}`}
                                target="_blank"
                              >
                                <img
                                  style={{ objectFit: "scale-down" }}
                                  width={80}
                                  // TODO: change base url in production
                                  src={`/api/misc/images/${element.fileName}`}
                                  alt=""
                                ></img>
                              </a>
                              <Typography>{`"${element.notes}"`}</Typography>
                            </center>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              disabled={this.state.details.status !== "pending"}
                              fullWidth
                              id={index}
                              label="Quoted Price (in $)"
                              name={index}
                              value={
                                this.state.details.otherItems[index].quotedPrice
                              }
                              onChange={(e) => {
                                let updatedValue = this.state.details;
                                updatedValue.otherItems[index].quotedPrice =
                                  e.target.value;
                                this.setState({ otherItems: updatedValue });
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <br />
                      {this.state.details.status === "pending" && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.updatePricing}
                          style={{ margin: 8 }}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  )}
              </Grid>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}
