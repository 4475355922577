import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import moment from "moment";
import ApiRequests from "../utils/ApiRequests";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(_id, name, price) {
  return { _id, name, price };
}

export default function ItemsTable(props) {
  const classes = useStyles();

  const rows = [];

  const updateItem = async (item) => {
    let val = window.prompt(`Updated price for "${item.name}"?`);
    try {
      let res = await ApiRequests.patch(`/api/items/${item._id}`, {
        price: val,
      });
      props.reload();
      alert("Price updated successfully!");
    } catch (error) {
      alert("Something went wrong");
    }
  };

  props.list.forEach((element) =>
    rows.push(createData(element._id, element.name, element.price))
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              // onClick={() => {
              //   props.onClick(row._id);
              // }}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name || "-"}
              </TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => {
                    updateItem(row);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.onClick(row._id);
                  }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
