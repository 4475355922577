import React from "react";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";

function App() {
  let token = localStorage.getItem("qat_token");
  if (token) return <Home />;
  else return <Login />;
}

export default App;
