import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(
  _id,
  sponsor,
  sponsorshipPercentage,
  startDateTime,
  endDateTime,
  venue,
  estimateWaitingTime,
  suggestedArrivalTime
) {
  return {
    _id,
    sponsor,
    sponsorshipPercentage,
    startDateTime,
    endDateTime,
    venue,
    estimateWaitingTime,
    suggestedArrivalTime,
  };
}

export default function EventsTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.sponsor,
        element.sponsorshipPercentage,
        element.startDateTime,
        element.endDateTime,
        element.venue,
        element.estimateWaitingTime,
        element.suggestedArrivalTime
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sponsor</TableCell>
            <TableCell>Sponsorship (%)</TableCell>
            <TableCell>Start Date/Time</TableCell>
            <TableCell>End Date/Time</TableCell>
            <TableCell>Venue</TableCell>
            <TableCell>Estimate Waiting Time</TableCell>
            <TableCell>Suggested Arrival Time</TableCell>
            <TableCell>Registrations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row._id}
              // onClick={() => {
              //   props.onClick(row._id);
              // }}
              // style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.sponsor || "-"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sponsorshipPercentage || "0"}
              </TableCell>
              <TableCell>
                {moment(row.startDateTime).format("Do MMM, h:mm a")}
              </TableCell>
              <TableCell>
                {moment(row.endDateTime).format("Do MMM, h:mm a")}
              </TableCell>
              <TableCell>{row.venue}</TableCell>
              <TableCell>{row.estimateWaitingTime}</TableCell>
              <TableCell>{row.suggestedArrivalTime}</TableCell>
              <TableCell>
                <Link
                  to={`/admin/events/${row._id}`}
                  // style={{ textDecoration: "none", color: "black" }}
                >
                  View All
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
