import React, { Component } from "react";
import EventsTable from "../../components/EventsTable";
import ApiRequests from "../../utils/ApiRequests";
import {
  CircularProgress,
  Modal,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Header from "../../components/Header";
import UserDetailsModal from "../../components/UserDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";
import moment from "moment";

export default class Events extends Component {
  state = {
    loading: true,
    items: [],
    showModal: false,
    selectedUserId: null,
    sponsorName: null,
    sponsorshipPercentage: null,
    startTime: null,
    endTime: null,
    venue: null,
    waitingTime: null,
    arrivalTime: null,
  };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("/api/events");
      this.setState({
        items: data["payload"]["events"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  addItem = async () => {
    if (
      this.state.sponsorName !== null &&
      this.state.sponsorshipPercentage !== null &&
      this.state.startTime !== null &&
      this.state.endTime !== null &&
      this.state.venue !== null &&
      this.state.waitingTime !== null &&
      this.state.arrivalTime !== null
    )
      try {
        let res = await ApiRequests.post("/api/events", {
          sponsor: this.state.sponsorName,
          sponsorshipPercentage: this.state.sponsorshipPercentage,
          startDateTime: this.state.startTime,
          endDateTime: this.state.endTime,
          venue: this.state.venue,
          estimateWaitingTime: this.state.waitingTime,
          suggestedArrivalTime: moment(
            `2020-01-01 ${this.state.arrivalTime}`
          ).format("h:mm A"),
        });
        let updatedItems = [...this.state.items];
        updatedItems.unshift(res.payload);
        this.setState({
          items: updatedItems,
          sponsorName: null,
          sponsorshipPercentage: null,
          startTime: null,
          endTime: null,
          venue: null,
          waitingTime: null,
          arrivalTime: null,
        });
        alert("Event added successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
    else alert("Please enter all the details!");
  };

  deleteItem = async (id) => {
    if (window.confirm("Are you sure, you want to delete the selected item?"))
      try {
        let res = await ApiRequests.delete(`/api/events/${id}`);
        let updatedItems = [...this.state.items];

        this.setState({
          items: updatedItems.filter((item) => item._id != id),
        });
        alert("Item deleted successfully!");
      } catch (error) {
        alert("Something went wrong");
      }
  };

  // selectUser = (id) => {
  //   console.log("incoming id", id);
  //   this.setState({
  //     selectedUserId: id,
  //     showModal: true,
  //   });
  //   console.log("set id", this.state.id);
  // };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={"Events"} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Events"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchItems}
            ></RefreshOutlined>
          }
        />
        {/* <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <UserDetailsModal userId={this.state.selectedUserId} />
          </div>
        </Modal> */}
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography style={{ fontStyle: "italic" }}>
              Click on Event to delete
            </Typography>
            <EventsTable
              list={this.state.items}
              onClick={(id) => {
                this.deleteItem(id);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontStyle: "bold" }}>Add New Event</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="sponsorName"
              label="Sponsor's Name"
              name="sponsorName"
              autoComplete="sponsorName"
              value={this.state.sponsorName || ""}
              onChange={(e) => {
                this.setState({ sponsorName: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="sponsorshipPercentage"
              label="Sponsorship Percentage"
              name="sponsorshipPercentage"
              autoComplete="sponsorshipPercentage"
              value={this.state.sponsorshipPercentage || ""}
              onChange={(e) => {
                this.setState({ sponsorshipPercentage: e.target.value });
              }}
            />
            <TextField
              id="start-datetime"
              label="Start Date/Time"
              type="datetime-local"
              value={this.state.startTime}
              onChange={(e) => {
                this.setState({ startTime: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {"  "}
            <TextField
              id="end-datetime"
              label="End Date/Time"
              type="datetime-local"
              value={this.state.endTime}
              onChange={(e) => {
                this.setState({ endTime: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="venue"
              label="Venue Address"
              name="venue"
              autoComplete="venue"
              value={this.state.venue || ""}
              onChange={(e) => {
                this.setState({ venue: e.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="waitingTime"
              label="Estimated Waiting Time"
              name="waitingTime"
              autoComplete="waitingTime"
              value={this.state.waitingTime || ""}
              onChange={(e) => {
                this.setState({ waitingTime: e.target.value });
              }}
            />
            <TextField
              id="arrival-time"
              label="Suggested Arrival Time"
              type="time"
              value={this.state.arrivalTime}
              onChange={(e) => {
                this.setState({ arrivalTime: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 600, // 5 min
              }}
            />
            <br />
            <br />
            <Button variant="contained" color="primary" onClick={this.addItem}>
              Add Event
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
