import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(_id, userLocation, pharmacy, medicines, createdAt, active) {
  return {
    _id,
    userLocation,
    pharmacy,
    medicines,
    createdAt,
    active,
  };
}

export default function SearchesTable(props) {
  const classes = useStyles();

  const rows = [];

  props.list.forEach((element) =>
    rows.push(
      createData(
        element._id,
        element.location,
        element.pharmacy,
        element.availability,
        element.createdAt,
        element.active
      )
    )
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>User Location</TableCell>
            <TableCell>Pharmacy</TableCell>
            <TableCell>Medicines</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Current Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                <a
                  target="blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${row.userLocation}`}
                >
                  {row.userLocation}
                </a>
              </TableCell>
              <TableCell>
                {row.pharmacy ? (
                  <a
                    target="blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${row.pharmacy.location}&query_place_id=${row.pharmacy.googlePlaceId}`}
                  >
                    {row.pharmacy.name}
                  </a>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>
                {row.medicines
                  .map((e) => `${e.variant.medicine.name} ${e.variant.name}`)
                  .join(", ")}
              </TableCell>
              <TableCell>
                {moment(row.createdAt).format("MMMM Do, h:mm a")}
              </TableCell>
              <TableCell>{row.active ? "Active" : "Completed"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
