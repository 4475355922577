import React, { Component } from "react";
import UsersTable from "../../components/UsersTable";
import ApiRequests from "../../utils/ApiRequests";
import { CircularProgress, Modal, Typography } from "@material-ui/core";
import Header from "../../components/Header";
import UserDetailsModal from "../../components/UserDetailsModal";
import { RefreshOutlined } from "@material-ui/icons";

export default class Dummy extends Component {
  state = {
    loading: true,
    users: [],
    showModal: false,
    selectedUserId: null,
  };

  componentDidMount() {
    // this.fetchUsers();
  }

  fetchUsers = async () => {
    this.setState({ loading: true });
    try {
      let data = await ApiRequests.get("/api/users");
      this.setState({
        users: data["payload"],
        loading: false,
      });
    } catch (error) {
      alert("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  selectUser = (id) => {
    console.log("incoming id", id);
    this.setState({
      selectedUserId: id,
      showModal: true,
    });
    console.log("set id", this.state.id);
  };

  render() {
    if (this.state.loading)
      return (
        <div>
          <Header title={this.props.title} />
          <center>
            <CircularProgress color="inherit" />
          </center>
        </div>
      );
    return (
      <div>
        <Header
          title={"Users"}
          icon={
            <RefreshOutlined
              style={{ marginLeft: "32px", verticalAlign: "middle" }}
              onClick={this.fetchUsers}
            ></RefreshOutlined>
          }
        />
        {/* <Modal open={this.state.showModal} onClose={this.handleClose}>
          <div style={{ margin: "64px" }}>
            <UserDetailsModal userId={this.state.selectedUserId} />
          </div>
        </Modal> */}
        {/* <Typography style={{ fontStyle: "italic" }}>
          Click on User's row to view Recent Activity
        </Typography> */}
        <UsersTable
          list={this.state.users}
          onClick={(id) => {
            this.selectUser(id);
          }}
        />
      </div>
    );
  }
}
